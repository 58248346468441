import React from 'react'
import Footer from './Footer'
import Navbar from './Navbar'

function About() {
    return (

        <div>
            <Navbar />
            <Footer />
        </div>
    )
}

export default About