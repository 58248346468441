// import React from "react";
// import Navbar from "./Navbar";
// import img1 from "../asset/imgs/5.png";
// import img2 from "../asset/imgs/1.png";
// import img3 from "../asset/imgs/9.png";
// import img4 from "../asset/imgs/11.png";
// import Footer from "./Footer";
// function Feet() {
//   return (
//     <div className="bg-dark">
//       <Navbar />
//       <div className="heroimgbox">
//         <p>
//           ZEUS TRANSPORTATION OFFERS THE BEST SELECTION OF LUXURY VEHICLES TO
//           GET YOU TO YOUR <br /> DESTINATION ON TIME.
//         </p>
//       </div>
//       <div className="container">
//         <div>
//           <h1 className="text-center my-5 headtest  ">
//             <span className="animate__rubberBand">SEDANS & SUVS</span>
//           </h1>
//         </div>
//         <div className="row my-5">
//           <div className="col-md-6 img-container">
//             <img
//               src={img1}
//               className="img-fluid w-75 mx-auto justify-content-center d-flex"
//               alt=""
//             />
//             <div className="inner-box">
//               <div className="inner-text">
//                 <h4 className="  ">CADILLAC XTS</h4>
//                 <p className="">
//                   Seats:3 <br /> Luggage:3 <br /> Amenities: Wifi <br />
//                   Refreshments: Water
//                 </p>
//               </div>
//             </div>
//           </div>
//           <div className="col-md-6 img-container">
//             <img
//               src={img2}
//               alt=""
//               className="img-fluid w-75 mx-auto justify-content-center d-flex"
//             />{" "}
//             <div className="inner-box">
//               <div className="inner-text">
//                 <h4 className="  ">CADILLAC ESCALADE ESV</h4>
//                 <p className="">
//                   Seats:3 <br /> Luggage:3 <br /> Amenities: Wifi <br />
//                   Refreshments: Water
//                 </p>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="row ">
//           <div className="col-md-6 img-container">
//             <img
//               src={img3}
//               alt=""
//               className="img-fluid w-75 h-100 mx-auto justify-content-center d-flex"
//             />
//             <div className="inner-box">
//               <div className="inner-text">
//                 <h4 className="  ">MERCEDES S</h4>
//                 <p className="">
//                   Seats:2 <br /> Luggage:2 <br /> Amenities: Wifi <br />
//                   Refreshments: Water
//                 </p>
//               </div>
//             </div>
//           </div>
//           <div className="col-md-6 img-container">
//             <img
//               src={img4}
//               alt=""
//               className="img-fluid w-75 h-100 mx-auto justify-content-center d-flex"
//             />
//             <div className="inner-box">
//               <div className="inner-text">
//                 <h4 className="  ">Chevrolet Suburban</h4>
//                 <p className="">
//                   Seats:5-6 <br /> Luggage:5-6 <br /> Amenities: Wifi <br />
//                   Refreshments: Water
//                 </p>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//       <div>
//         <Footer />
//       </div>
//     </div>
//   );
// }

// export default Feet;


import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import img1 from "../asset/imgs/5.png";
import img2 from "../asset/imgs/1.png";
import img3 from "../asset/imgs/9.png";
import img4 from "../asset/imgs/11.png";
import img5 from "../asset/imgs/13.png";  // Add new image import
import img6 from "../asset/imgs/14.png";  // Add new image import
import img7 from "../asset/imgs/15.png";  // Add new image import
import img8 from "../asset/imgs/16.png";  // Add new image import
import img9 from "../asset/imgs/17.png";  // Add new image import
import img10 from "../asset/imgs/18.png"; // Add new image import
import img11 from "../asset/imgs/19.png"; // Add new image import
import img12 from "../asset/imgs/20.png"; // Add new image import

function Feet() {
  return (
    <div className="bg-dark">
      <Navbar />
      <div className="heroimgbox">
        <p>
          ZEUS TRANSPORTATION OFFERS THE BEST SELECTION OF LUXURY VEHICLES TO
          GET YOU TO YOUR <br /> DESTINATION ON TIME.
        </p>
      </div>
      <div className="container">
        <div>
          <h1 className="text-center my-5 headtest  ">
            <span className="animate__rubberBand">SEDANS & SUVS</span>
          </h1>
        </div>
        <div className="row my-5">
          {carRender(img1, "CADILLAC XTS", "3", "3", "Wifi", "Water")}
          {carRender(img2, "CADILLAC ESCALADE ESV", "3", "3", "Wifi", "Water")}
          {carRender(img3, "MERCEDES S", "2", "2", "Wifi", "Water")}
          {carRender(img4, "Chevrolet Suburban", "5-6", "5-6", "Wifi", "Water")}

          {/* Add 8 more cars here */}
          {carRender(img5, "Cadillac XT6", "4", "4", "Wifi", "Water")}
          {carRender(img6, "Lincoln Aviator", "4", "4", "Wifi", "Water")}
          {carRender(img7, "Chevrolet Suburban", "5-6", "5-6", "Wifi", "Water")}
          {carRender(img8, "GMC Yukon XL", "5-6", "5-6", "Wifi", "Water")}
          {carRender(img9, "Lincoln Navigator L", "5", "5", "Wifi", "Water")}
          {carRender(img10, "cedes Benz S Class", "2", "2", "Wifi", "Water")}
          {carRender(img11, "Mercedes Benz Sprinter Shuttle", "14", "14", "Wifi", "Water")}
          {carRender(img12, "Mercedes Benz Sprinter Limo Style", "10-12", "0", "Wifi", "Water")}
        </div>
      </div>
      <Footer />
    </div>
  );
}

// Helper function to render car details
const carRender = (imgSrc, title, seats, luggage, amenities, refreshments) => (
  <div className="col-md-6 img-container" key={title}>
    <img
      src={imgSrc}
      alt=""
      className="img-fluid w-75 mx-auto justify-content-center d-flex"
    />
    <div className="inner-box">
      <div className="inner-text">
        <h4 className="  ">{title}</h4>
        <p className="">
          Seats: {seats} <br /> Luggage: {luggage} <br /> Amenities: {amenities} <br />
          Refreshments: {refreshments}
        </p>
      </div>
    </div>
  </div>
);

export default Feet;
